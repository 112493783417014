import React from "react";
import "./styles.scss";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import Carousel from "views/index-sections/Carousel";

function LandingPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [phoneFocus, setPhoneFocus] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [messageContent, setMessageContent] = React.useState("");

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const handleSendMessage = e => {
    e.preventDefault();
    window.emailjs
      .send(
        "default_service",
        "template_26pgdS7E",
        {
          senderName: name,
          senderMail: email,
          senderPhone: phone,
          messageContent
        },
        "user_HqJODzrpfDUTJSxfbZIKR"
      )
      .then(response => {
        if (response.status === 200) {
          alert("Mesajınız Başarıyla Gönderildi");
        }
      });
  };
  return (
    <>
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Biz Kimiz?</h2>
                <h5 className="description">
                  1981 Yılından beri yapı market sektöründe faaliyette bulunan
                  şirketimiz, {(new Date().getFullYear()) - 1981} yıllık perakende sektör tecrübesinin yanına son
                  yıllarda faaliyet göstermeye başladığı toptan satış ile
                  sektörde yer bulmaktadır. Ayrıca bünyemize, alanında güçlü
                  satın alma, mühendis ve dış ticaret tecrübesi olan ekip
                  arkadaşları katarak bu pazarda toptan satışa dair doğrudan
                  ithalat faaliyetlerine başladık.
                </h5>
                <h5 className="description">
                  Coşkun yapı market olarak genç ve yenilikçi ekibimiz ile
                  müşterilerimizin gereksinimleri ile beklentilerinin
                  karşılanmasını sağlamak, hizmette kalite, doğruluk ve istikrar
                  sağlamak, müşteri için ekonomik üstünlükleri olan ürünleri
                  nihai ve toptan tüketiciye tedarik etmeyi misyon edindik.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" +
                        require("assets/img/siva-filesi-turuncu.jpg") +
                        ")"
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      Sıva filesi yalıtım gereken yapılarda dış ve iç
                      cephelerinde kullanılır
                      <br></br>
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/4.jpeg") + ")"
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/14.jpeg") + ")"
                    }}
                  ></div>
                  <h3></h3>
                  <p className="side-content">
                    Coşkun Yapı Market tecrübeli ekibi ile satın alma birimi
                    eşliğinde ürünlerini Çin'deki üreticiden bizzat satın alarak
                    kendisi ithal etmektedir.
                  </p>
                  <p className="side-content">
                    İthal ettiği ürünlerle Türkiye'deki endüstriyel tüketiciye
                    tedarik sağlar.
                  </p>
                  <p className="side-content">-</p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="section section-team text-center">
          <Container>
            <h2 className="title">Ürünlerimiz</h2>
            <div className="team">
              <Row>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/turuncu-siva-filesi.jpg")}
                      style={{ width: "200px!important" }}
                    ></img>
                    <h4 className="title">SIVA FİLESİ</h4>
                    <p className="category text-info">Yalıtım Malzemesi</p>
                    <p className="description" style={{ color: "orange" }}>
                      Turuncu
                    </p>
                    <p className="description" style={{ color: "orange" }}>
                      4x4mm{" "}
                    </p>
                    <p className="description" style={{ color: "orange" }}>
                      160 gram{" "}
                    </p>
                    <p className="description" style={{ color: "orange" }}>
                      50 metre
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/yesil-pvc-puntali-tel.jpg")}
                    ></img>
                    <h4 className="title">PVC PUNTALI TEL</h4>
                    <p className="category text-info">Yapı Malzemesi</p>
                    <p className="description" style={{ color: "green" }}>
                      Yeşil
                    </p>
                    <p className="description" style={{ color: "green" }}>
                      12x12 / 25x25 / 50x50 mm
                    </p>
                    <p className="description" style={{ color: "green" }}>
                      1x25 / 1.2x25 / 1.5x25 metre
                    </p>
                    <p className="description" style={{ color: "green" }}></p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="carousel-container">
          <Carousel />
        </div>
        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">MESAJ BIRAKIN</h2>
            <p className="description">
              Partnerliğiniz bizim için çok değerli!
            </p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Adınız ve Soyadınız"
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                    onChange={event => setName(event.target.value)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Mail Adresiniz"
                    type="text"
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                    onChange={event => setEmail(event.target.value)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (phoneFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons tech_mobile"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Telefon Numaranız"
                    type="text"
                    onFocus={() => setPhoneFocus(true)}
                    onBlur={() => setPhoneFocus(false)}
                    onChange={event => setPhone(event.target.value)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="name"
                    placeholder="Mesajınız"
                    rows="4"
                    type="textarea"
                    onChange={event => setMessageContent(event.target.value)}
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={handleSendMessage}
                    size="lg"
                  >
                    Mesaj Bırak
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
