/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import "./footer.scss";

// core components

const ListItem = ({ label, message }) => {
  return (
    <li>
      <div className="list-item-container">
        <div className="item-label">{`${label}`}</div>
        <div className="item-message">{`: ${message}`}</div>
      </div>
    </li>
  );
};

function DefaultFooter() {
  return (
    <div className="footer-container">
      <h2 className="contact-us-text">BİZİMLE İLETİŞİME GEÇİN</h2>
      <div className="address">
        Merkez Mahallesi Ortaçeşme Caddesi No:10, Coşkun Yapı Market, Sarıyer,
        Istanbul, Türkiye
      </div>
      <ul>
        <ListItem label="Gsm" message="+90 539 342 0553" />
        <ListItem label="Tel" message="+90 212 242 6280" />
        <ListItem label="email" message="info@coskunyapimarket.com" />
      </ul>
    </div>
  );
}

export default DefaultFooter;
